import React from "react";
import {
  Grid,
  Typography,
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { useFirestoreConnect, useFirestore } from "react-redux-firebase";
import { useHistory } from "react-router-dom";

const UserTestList = () => {
  const profile = useSelector((state) => state.firebase.profile);
  const auth = useSelector((state) => state.firebase.auth);
  useFirestoreConnect([{ collection: "tests" }]);
  const tests = useSelector((state) => state.firestore.ordered.tests);
  const requestingTests = useSelector(
    (state) => state.firestore.status.requesting.tests
  );
  const firestore = useFirestore();
  const history = useHistory();
  const handleStartTest = (test) => {
    if (auth.uid)
      firestore
        .collection("users")
        .doc(auth.uid)
        .update({ tests: profile.tests ? [...profile.tests, test] : [test] })
        .then(() => history.push(`/dashboard/test/${test.title}`, test.id));
  };
  const handleContinueTest = (test) => {
    history.push(`/dashboard/test/${test.title}`, test.id);
  };

  const handleResultTest = (test) => {
    history.push(`/dashboard/test/${test.title}/result`, test);
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5">
          Hola {profile.name}, bienvenido a Mejor Test
        </Typography>
      </Grid>
      <Grid container spacing={3}>
        {requestingTests && <CircularProgress />}
        {tests &&
          tests.length !== 0 &&
          tests.map(
            (element) =>
              element.questions &&
              element.questions.length !== 0 && (
                <Grid item xs={12} md={6} lg={4} key={element.id}>
                  <Card elevation={7}>
                    <CardActionArea onClick={() => handleStartTest(element)}>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          {element.title}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          {element.questions.length} preguntas
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                    <CardActions>
                      <Button
                        onClick={
                          profile &&
                          profile.tests &&
                          profile.tests.some(
                            (userTest) => userTest.id === element.id
                          )
                            ? profile.tests.find(
                                (userTest) => userTest.id === element.id
                              ).submitted
                              ? () => handleResultTest(element)
                              : () => handleContinueTest(element)
                            : () => handleStartTest(element)
                        }
                        size="small"
                        color="primary"
                      >
                        {profile &&
                        profile.tests &&
                        profile.tests.some(
                          (userTest) => userTest.id === element.id
                        )
                          ? profile.tests.find(
                              (userTest) => userTest.id === element.id
                            ).submitted
                            ? `Ver resultado`
                            : `Seguir Test`
                          : `Comenzar Test`}
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              )
          )}
      </Grid>
    </Grid>
  );
};

export default UserTestList;
