import React from "react";
import { Grid, Typography } from "@material-ui/core";
import ExamForm from "./examForm";
const TestCreate = () => {
  return (
    <Grid item xs={12}>
      <Grid container spacing={3}>
        <Grid item>
          <Typography variant="h5" color="textSecondary">
            Nuevo test
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ExamForm />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TestCreate;
