import React from "react";
import { Grid } from "@material-ui/core";
import { useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import IndicatorForm from "./indicatorForm";
const IndicatorEdit = (props) => {
  useFirestoreConnect(() => [
    { collection: "indicators", doc: props.match.params.id },
  ]);
  const indicator = useSelector(
    ({ firestore: { data } }) =>
      data.indicators && data.indicators[props.match.params.id]
  );
  return (
    <Grid item xs={12}>
      <Grid container spacing={3}>
        <Grid item>Editar indicador {indicator && indicator.title}</Grid>
      </Grid>
      {indicator && (
        <Grid container spacing={3}>
          <Grid item>
            <IndicatorForm
              indicator={indicator}
              indicatorId={props.match.params.id}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default IndicatorEdit;
