import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { Switch, Route } from "react-router-dom";
import UserList from "./userList";
import UserDetails from "./userDetails";
import ExamResult from "./examResult";
/* import UserCreate from "./createUser"; */
const Users = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item>
            <Typography variant="h5" gutterBottom>
              Usuarios
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Switch>
        {/* <Route path="/users/create" component={UserCreate} /> */}
        {/* <Route path="/users/edit/:id" component={IndicatorEdit} /> */}
        <Route path="/users/:id/test-result/:testId" component={ExamResult} />
        <Route path="/users/:id" component={UserDetails} />
        <Route path="/users" component={UserList} />
      </Switch>
    </Grid>
  );
};

export default Users;
