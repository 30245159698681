import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "./components/home/home";
import Login from "./components/login/login";
import PrivateRoute from "./components/privateRoute/privateRoute";
import AdminRoute from "./components/privateRoute/adminRoute";
import UserDashboard from "./components/userDashboard/userDashboard";

const App = () => {
  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/register" component={Login} />
      <PrivateRoute path="/dashboard">
        <UserDashboard />
      </PrivateRoute>
      <AdminRoute path="/">
        <Home />
      </AdminRoute>
    </Switch>
  );
};

export default App;
