import React from "react";
import { Grid, Typography, Slider, Divider } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
const UserExamResult = (props) => {
  useFirestoreConnect(() => [{ collection: "indicators" }]);
  const indicators = useSelector((state) => state.firestore.ordered.indicators);
  const test = useSelector((state) =>
    state.firebase.profile.tests.find(
      (element) => element.id === props.location.state.id
    )
  );
  const getIndicatorResult = (indicator) => {
    var result = 0;
    test.questions.forEach((element) => {
      const questionResult = element.result;
      const currentIndicator = element.indicators.find(
        (element) => element.title === indicator.title
      );
      console.log({ indicator, currentIndicator });
      if (currentIndicator) {
        if (currentIndicator.importance === "plus") {
          result += questionResult * currentIndicator.affection;
        } else if (currentIndicator.importance === "minus") {
          result += questionResult * (currentIndicator.affection * -1);
        }
      }
    });
    return result;
  };

  const getIndicatorMinus = (indicator) => {
    var result = 0;
    test.questions.forEach((element) => {
      const currentIndicator = element.indicators.find(
        (element) => element.title === indicator.title
      );
      if (currentIndicator) {
        if (currentIndicator.importance === "minus") {
          result += 5 * (currentIndicator.affection * -1);
        }
      }
    });
    return result;
  };

  const getIndicatorMax = (indicator) => {
    var result = 0;
    test.questions.forEach((element) => {
      const currentIndicator = element.indicators.find(
        (element) => element.title === indicator.title
      );
      if (currentIndicator) {
        if (currentIndicator.importance === "plus") {
          result += 5 * currentIndicator.affection;
        }
      }
    });
    return result;
  };

  const getIndicatorPercent = (indicator) => {
    var result = 0;
    const maximum = getIndicatorMax(indicator);
    const minimum = getIndicatorMinus(indicator);
    const indicatorResult = getIndicatorResult(indicator);

    const absoluteValue = maximum + minimum * -1;
    var test = 0;
    if (indicatorResult >= 0) {
      test = minimum * -1 + indicatorResult;
    } else {
      test = minimum * -1 + indicatorResult;
    }
    result = (test * 100) / absoluteValue;

    return result.toFixed(0);
  };

  return (
    <Grid item xs={12}>
      <Grid container spacing={3}>
        <Grid item>Restulado {test && test.title}</Grid>
      </Grid>
      {test && (
        <Grid container spacing={2} direction="column">
          {indicators &&
            indicators.map((indicator) => (
              <>
                <Grid item>
                  <Typography variant="h6">{indicator.title}</Typography>
                </Grid>
                <br></br>

                <Grid item>
                  <Grid container direction="row" justify="space-between">
                    <Grid item>
                      <Typography variant="h5">0%</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Slider
                        value={getIndicatorPercent(indicator)}
                        aria-labelledby="discrete-slider-small-steps"
                        step={10}
                        marks
                        min={0}
                        max={100}
                        valueLabelDisplay="on"
                        /* onChange={(e, val) => setValue(val)} */
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="h5">100%</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography variant="body1">
                    {indicator.description ?? ""}
                  </Typography>
                </Grid>
                <Grid item>
                  <Divider></Divider>
                </Grid>
              </>
            ))}
        </Grid>
      )}
    </Grid>
  );
};

export default UserExamResult;
