import React from "react";
import {
  CssBaseline,
  Container,
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  Button,
  Grid,
} from "@material-ui/core";
import { Switch, Route, Link } from "react-router-dom";
import UserTestList from "./userTestList";
import { useFirebase } from "react-redux-firebase";
import UserTestResolve from "./userTestResolve";
import UserExamResult from "./userTestResult";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexFlow: "column",
    height: "100vh",
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
    flex: "1 1 auto",
    height: "100%",
  },
}));
const UserDashboard = () => {
  const classes = useStyles();
  const firebase = useFirebase();
  const handleLogout = () => {
    firebase.auth().signOut();
  };
  return (
    <React.Fragment>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="relative">
          <Toolbar>
            <Grid justify="space-between" container>
              <Grid item>
                <Typography variant="h6" color="inherit" noWrap>
                  Mejor Test
                </Typography>
              </Grid>

              <Grid item>
                <Button
                  component={Link}
                  to="/dashboard"
                  color="inherit"
                  style={{ marginRight: "15px" }}
                >
                  Inicio
                </Button>
                <Button
                  onClick={handleLogout}
                  color="secondary"
                  variant="contained"
                >
                  Cerrar Sesion
                </Button>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <main className={classes.content}>
          <div className={classes.heroContent}>
            <Container maxWidth="lg" className={classes.content}>
              <Switch>
                <Route
                  path="/dashboard/test/:title/result"
                  component={UserExamResult}
                />
                <Route
                  path="/dashboard/test/:title"
                  component={UserTestResolve}
                />
                <Route path="/dashboard" component={UserTestList} />
              </Switch>
            </Container>
          </div>
        </main>
      </div>
    </React.Fragment>
  );
};

export default UserDashboard;
