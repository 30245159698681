import React from "react";
import {
  Grid,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Switch,
  Paper,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useFirestoreConnect, useFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
const IndicatorList = () => {
  useFirestoreConnect(["indicators"]);
  const indicators = useSelector((state) => state.firestore.ordered.indicators);
  const requesting = useSelector(
    (state) => state.firestore.status.requesting.indicators
  );
  const firestore = useFirestore();
  const handleDisable = (id, status) => {
    firestore.collection("indicators").doc(id).update({ active: status });
  };
  const handleDelete = (id) => {
    firestore.collection("indicators").doc(id).delete();
  };
  return (
    <Grid item xs={12}>
      <Grid container spacing={3} alignItems="center">
        <Grid item>Listado de indicadores</Grid>
        <Grid item>
          <Button
            variant="text"
            color="primary"
            component={Link}
            to="/indicators/create"
          >
            Nuevo
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs>
          {requesting ? (
            <CircularProgress />
          ) : (
            indicators && (
              <List>
                {indicators.map((element) => (
                  <ListItem
                    component={Paper}
                    elevation={7}
                    style={{ marginBottom: "15px" }}
                    key={element.id}
                  >
                    <ListItemText primary={element.title} />
                    <ListItemSecondaryAction>
                      <IconButton
                        component={Link}
                        to={`/indicators/edit/${element.id}`}
                      >
                        <EditIcon color="primary" />
                      </IconButton>
                      <Switch
                        checked={element.active}
                        onChange={() =>
                          handleDisable(element.id, !element.active)
                        }
                      />
                      <IconButton onClick={() => handleDelete(element.id)}>
                        <DeleteIcon style={{ color: "red" }} />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            )
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default IndicatorList;
