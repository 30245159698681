import firebase from "firebase/app";
import ReactDOM from "react-dom";
import React from "react";
import "firebase/auth";
import "firebase/firestore";
import { createStore, compose } from "redux";
import { Provider, useSelector } from "react-redux";
import { ReactReduxFirebaseProvider, isLoaded } from "react-redux-firebase";
import App from "./App";
import { rootReducer } from "./reducers/reducers";
import { createFirestoreInstance } from "redux-firestore";
import { BrowserRouter } from "react-router-dom";
import DevTools from "./components/devTools/devTools";
import "./index.scss";
import { Grid, CircularProgress } from "@material-ui/core";

const firebaseConfig = {
  apiKey: "AIzaSyDdRKk9kzWym96Zj3JSC2bPmIqsre_JEFQ",
  authDomain: "mejortest-683a3.firebaseapp.com",
  databaseURL: "https://mejortest-683a3.firebaseio.com",
  projectId: "mejortest-683a3",
  storageBucket: "mejortest-683a3.appspot.com",
  messagingSenderId: "789616567452",
  appId: "1:789616567452:web:f2da0286d4b0e379967959",
  measurementId: "G-5WRXRQMJZ6",
};

const rrfConfig = {
  userProfile: "users",
  profileParamsToPopulate: [{ child: "role", root: "roles" }],
  useFirestoreForProfile: true,
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();

const enhancer = compose(DevTools.instrument());

const initialState = {};
const store = createStore(rootReducer, initialState, enhancer);

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

const AuthIsLoaded = ({ children }) => {
  const auth = useSelector((state) => state.firebase.auth);
  const profile = useSelector((state) => state.firebase.profile);
  if (!isLoaded(auth) || !isLoaded(profile))
    return (
      <Grid
        container
        spacing={3}
        style={{ height: "90vh" }}
        direction="column"
        alignItems="center"
        justify="center"
      >
        <CircularProgress color="secondary" />
      </Grid>
    );
  return children;
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <BrowserRouter>
          <AuthIsLoaded>
            <App />
            {/* <DevTools /> */}
          </AuthIsLoaded>
        </BrowserRouter>
      </ReactReduxFirebaseProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
