import React from "react";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  CardHeader,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import { Link } from "react-router-dom";

const Suggestions = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom>
          Buen día Osvaldito, ¿Que quiere hacer hoy?
        </Typography>
      </Grid>
      <Grid item lg={12}>
        <Grid container spacing={3}>
          <Grid item>
            <Typography variant="h5" gutterBottom>
              Tests
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to="/tests/create"
            >
              Nuevo Test
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item md>
            <Card elevation={7}>
              <CardHeader
                avatar={<TimelapseIcon fontSize="large" color="primary" />}
                title="Tests sin terminar"
              />
              <CardContent>
                <Typography color="textSecondary">
                  12 Tests en proceso.
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="medium" color="primary" disableElevation>
                  Ver mas
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item md>
            <Card elevation={7}>
              <CardHeader
                avatar={
                  <CheckCircleIcon
                    fontSize="large"
                    style={{ color: "green" }}
                  />
                }
                title="Tests completados"
              />
              <CardContent>
                <Typography color="textSecondary">
                  235 tests completados
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="medium" color="primary" disableElevation>
                  Ver mas
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={12}>
        <Grid container spacing={3}>
          <Grid item>
            <Typography variant="h5" gutterBottom>
              Usuarios
            </Typography>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary">
              Nuevo Usuario
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item md>
            <Card elevation={7}>
              <CardHeader
                avatar={
                  <HighlightOffIcon fontSize="large" style={{ color: "red" }} />
                }
                title="Usuarios que no han comenzado"
              />
              <CardContent>
                <Typography color="textSecondary">
                  13 Usuarios fueron invitados y no han comenzado su test.
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="medium" color="primary" disableElevation>
                  Ver mas
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item md>
            <Card elevation={7}>
              <CardHeader
                avatar={
                  <ThumbUpIcon fontSize="large" style={{ color: "green" }} />
                }
                title="Usuarios que han finalizado"
              />
              <CardContent>
                <Typography color="textSecondary">
                  235 Usuarios han terminado sus tests.
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="medium" color="primary" disableElevation>
                  Ver mas
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Suggestions;
