import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Typography,
  Grid,
  FormGroup,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Paper,
  TableContainer,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircleOutline";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import QuestionDialog from "../dialogs/questionDialog";
import { useFirestore } from "react-redux-firebase";
import { useHistory } from "react-router-dom";
const ExamForm = ({ test, indicators, testId, testQuestions }) => {
  const [busy, setBusy] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [questionList, setQuestionList] = useState([]);
  const [title, setTitle] = useState(test?.title ?? "");
  const [question, setQuestion] = useState(undefined);
  const firestore = useFirestore();
  const history = useHistory();

  useEffect(() => {
    if (test)
      if (testQuestions) {
        setQuestionList(testQuestions);
      }
  }, [setQuestionList, test, testQuestions, questionList]);

  const addQuestion = (question) => {
    firestore
      .collection("tests")
      .doc(testId)
      .update({
        questions: [
          ...questionList,
          { id: questionList[questionList.length - 1].id + 1, ...question },
        ],
      });
  };

  const updateQuestion = (question) => {
    firestore
      .collection("tests")
      .doc(testId)
      .collection("questions")
      .doc(question.id)
      .update(question)
      .then(() =>
        firestore
          .collection("tests")
          .doc(testId)
          .update({
            questions: [
              ...questionList.filter((element) => element.id !== question.id),
              question,
            ],
          })
      );
  };

  const handleDeleteQuestion = (question) => {
    firestore
      .collection("tests")
      .doc(testId)
      .update({
        questions: [
          ...questionList.filter((element) => element.id !== question.id),
        ],
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setBusy(true);
    if (!test) {
      var result = await firestore
        .collection("tests")
        .add({ title: title, active: true });
      setBusy(false);
      if (result.id) return history.push(`/tests/edit/${result.id}`);
    } else {
      firestore
        .collection("tests")
        .doc(testId)
        .update({ title: title, questions: questionList })
        .then(() => history.push("/tests"));
    }
  };
  if (busy) return <CircularProgress />;
  return (
    <form onSubmit={handleSubmit}>
      <Grid item xs={6}>
        <FormGroup>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="title"
            label="Titulo"
            name="title"
            autoComplete="title"
            onChange={(e) => setTitle(e.target.value)}
            autoFocus
            defaultValue={title}
          />
        </FormGroup>
      </Grid>
      {test && questionList && (
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item style={{ alignSelf: "center" }}>
              <Typography variant="subtitle1">
                Preguntas, Tiene {questionList.length} preguntas cargadas
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={() => setOpenModal(true)}>
                <AddCircleIcon color="primary" />
              </IconButton>
            </Grid>
          </Grid>
          <br />
          <Grid container>
            <Grid item xs={12}>
              {questionList && questionList.length !== 0 && (
                <TableContainer component={Paper} elevation={7}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Pregunta</TableCell>
                        {indicators &&
                          indicators.length > 0 &&
                          indicators.map((element) => (
                            <TableCell key={`${element.title}`}>
                              {element.title}
                            </TableCell>
                          ))}
                        <TableCell>Acciones</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {questionList.map((questionElement) => (
                        <TableRow key={`${questionElement.id}`}>
                          <TableCell>{questionElement.question}</TableCell>
                          {indicators &&
                            indicators.length > 0 &&
                            indicators.map((element) => (
                              <TableCell
                                key={`${element.title}`}
                                style={{ padding: "0px" }}
                              >
                                {questionElement.indicators
                                  .filter(
                                    (indicatorElement) =>
                                      indicatorElement.title === element.title
                                  )
                                  .map((result) => (
                                    <Paper
                                      key={result.title}
                                      elevation={7}
                                      style={{
                                        backgroundColor:
                                          result.importance === "minus"
                                            ? "red"
                                            : result.importance === "plus"
                                            ? "lightgreen"
                                            : "inherit",
                                        padding: "16px",
                                      }}
                                    >
                                      {result.affection}
                                    </Paper>
                                  ))}
                              </TableCell>
                            ))}
                          <TableCell>
                            <IconButton
                              onClick={() => {
                                setQuestion(questionElement);
                                setOpenModal(true);
                              }}
                            >
                              <EditIcon color="primary" />
                            </IconButton>
                            <IconButton
                              onClick={() =>
                                handleDeleteQuestion(questionElement)
                              }
                            >
                              <DeleteIcon style={{ color: "red" }} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Grid>
            <Grid item xs={12}></Grid>
          </Grid>
          <QuestionDialog
            openModal={openModal}
            setOpenModal={setOpenModal}
            indicators={indicators}
            addQuestion={addQuestion}
            updateQuestion={updateQuestion}
            questionElement={question}
            setQuestionElement={setQuestion}
          />
        </Grid>
      )}
      <br />
      {!busy && !test && (
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={title ? false : true}
        >
          Guardar
        </Button>
      )}
      {!busy && test && (
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={title ? false : true}
        >
          Actualizar
        </Button>
      )}
      <Button
        onClick={() => history.goBack()}
        variant="contained"
        color="secondary"
        style={{ marginLeft: "10px" }}
      >
        Volver
      </Button>
    </form>
  );
};

export default ExamForm;
