import React, { useState } from "react";
import {
  TextField,
  Button,
  FormGroup,
  CircularProgress,
} from "@material-ui/core";
import { useFirestore } from "react-redux-firebase";
import { useHistory } from "react-router-dom";
const IndicatorForm = ({ indicator, indicatorId }) => {
  const [title, setTitle] = useState(indicator?.title ?? "");
  const [description, setDescription] = useState(indicator?.description ?? "");
  const [busy, setBusy] = useState(false);
  const firestore = useFirestore();
  const history = useHistory();
  const handleSubmit = async (e) => {
    console.log(indicator);
    e.preventDefault();
    setBusy(true);
    if (!indicator) {
      var result = await firestore
        .collection("indicators")
        .add({ title: title, active: true, description });
      setBusy(false);
      if (result.id) return history.goBack();
    } else {
      await firestore
        .collection("indicators")
        .doc(indicatorId)
        .update({ title: title, description: description })
        .then(history.goBack)
        .catch((error) => alert(error));
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <FormGroup>
        <TextField
          margin="normal"
          variant="outlined"
          color="primary"
          label="Nombre"
          defaultValue={title}
          onChange={(e) => setTitle(e.target.value)}
        />

        <TextField
          margin="normal"
          variant="outlined"
          color="primary"
          label="Descripcion"
          defaultValue={description}
          onChange={(e) => setDescription(e.target.value)}
          multiline
          rows={4}
        />
      </FormGroup>
      {busy ? (
        <CircularProgress />
      ) : !indicator ? (
        <Button variant="contained" color="primary" type="submit">
          Agregar
        </Button>
      ) : (
        <Button variant="contained" color="primary" type="submit">
          Actualizar
        </Button>
      )}
    </form>
  );
};

export default IndicatorForm;
