import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  CircularProgress,
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  Button,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
const UserDetails = (props) => {
  const [user, setUser] = useState();
  const history = useHistory();
  useEffect(() => {
    if (props.location.state) setUser(props.location.state);
  }, [props]);

  if (!user)
    return (
      <Grid
        container
        style={{ height: "100%" }}
        justify="center"
        alignItems="center"
        alignContent="center"
      >
        <CircularProgress />
      </Grid>
    );
  return (
    <Grid item xs={12}>
      <Grid container spacing={3}>
        <Grid item>
          <Typography variant="h5" color="textSecondary">
            {user.name} {user.lastName}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {user.tests && user.tests.map((element) => (
          <Grid item xs={12} md={6} lg={4} key={element.id}>
            <Card elevation={7}>
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    {element.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {element.questions.length} preguntas
                  </Typography>
                  <Typography variant="body2" color="textPrimary" component="p">
                    {user.tests &&
                    user.tests.some((userTest) => userTest.id === element.id)
                      ? user.tests.find(
                          (userTest) => userTest.id === element.id
                        ).submitted
                        ? `Test Finalizado`
                        : `Seguir Test`
                      : `Comenzar Test`}
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button
                  size="small"
                  color="primary"
                  onClick={() =>
                    history.push(
                      `/users/${user.id}/test-result/${element.id}`,
                      element
                    )
                  }
                >
                  Ver Resultados
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default UserDetails;
