import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  CircularProgress,
  Slider,
  Button,
  Chip,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";
import EditIcon from "@material-ui/icons/Edit";
const UserTestResolve = (props) => {
  const firestore = useFirestore();
  const auth = useSelector((state) => state.firebase.auth);
  const [question, setQuestion] = useState();
  const [value, setValue] = useState(2);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const tests = useSelector((state) => state.firebase.profile.tests);
  const test = useSelector((state) =>
    state.firebase.profile.tests?.find(
      (element) => element.id === props.location.state
    )
  );
  useEffect(() => {
    if (test) {
      setQuestion(
        test.questions.find((element) => element.result === undefined)
      );
      if (!test.questions.some((element) => element.result === undefined)) {
        setLoading(true);
        setSubmitting(true);
        firestore
          .collection("users")
          .doc(auth.uid)
          .update({
            tests: [
              ...tests.filter((element) => element.id !== test.id),
              { ...test, submitted: true },
            ],
          })

          .then(() => {
            setLoading(false);
            setSubmitting(false);
          });
      }
    }
  }, [test, auth, firestore, tests]);

  const handleNextQuestion = async () => {
    setLoading(true);
    firestore
      .collection("users")
      .doc(auth.uid)
      .update({
        tests: [
          ...tests.filter((element) => element.id !== test.id),
          {
            ...test,
            questions: [
              ...test.questions.filter((element) => element.id !== question.id),
              { ...question, result: value },
            ],
          },
        ],
      })
      .then(() => {
        setLoading(false);
        setValue(0);
      });
  };
  if (!test)
    return (
      <Grid
        container
        spacing={3}
        style={{ height: "100%" }}
        direction="column"
        alignItems="center"
        justify="center"
      >
        <Typography variant="h2" color="secondary">
          Este test no le pertenece
        </Typography>
      </Grid>
    );
  if (test.submitted)
    return (
      <Grid
        container
        spacing={3}
        style={{ height: "100%" }}
        direction="column"
        alignItems="center"
        justify="center"
      >
        <Typography variant="h2" color="secondary">
          Usted ya termino este test.
        </Typography>
      </Grid>
    );
  if (
    !question &&
    test.questions.some((element) => element.result === undefined)
  )
    return (
      <Grid
        container
        style={{ height: "100%" }}
        justify="center"
        alignItems="center"
        alignContent="center"
      >
        <CircularProgress />
      </Grid>
    );
  return (
    <Grid container spacing={3} style={{ height: "100%" }}>
      <>
        <Grid item xs={12}>
          <Typography variant="h5" color="secondary">
            {submitting ? "" : question.question}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Slider
              value={value}
              aria-labelledby="discrete-slider-small-steps"
              step={1}
              marks={[
                { value: 0, label: "Nunca" },
                { value: 1, label: "Algo" },
                { value: 2, label: "Algo Mas" },
                { value: 3, label: "Muchito" },
                { value: 4, label: "Mucho" },
                { value: 5, label: "Siempre" },
              ]}
              min={0}
              max={5}
              valueLabelDisplay="on"
              onChange={(e, val) => setValue(val)}
            />
          )}
        </Grid>
        <Grid item xs={12} md={6} style={{ textAlign: "center" }}>
          <Button
            disabled={loading ? true : false}
            onClick={handleNextQuestion}
            variant="contained"
            color="primary"
            size="large"
          >
            {test.questions.filter((element) => element.result === undefined)
              .length === 1
              ? "Finalizar test"
              : "Siguiente pregunta"}
          </Button>
        </Grid>
      </>
      <Grid item xs={12}>
        {test.questions
          .sort((a, b) => (a.result ? -1 : 1))
          .map((element, index) =>
            question === element ? (
              <Chip
                style={{ margin: "5px" }}
                key={element.id}
                label={`Pregunta ${element.question}`}
                color="secondary"
              />
            ) : element.result !== undefined ? (
              <Chip
                style={{ margin: "5px" }}
                key={element.id}
                label={`Pregunta ${element.question}`}
                clickable
                color="primary"
                onClick={() => {
                  setQuestion(element);
                  setValue(element.result);
                }}
                icon={<EditIcon fontSize="small" />}
              />
            ) : (
              <Chip
                style={{ margin: "5px" }}
                key={element.id}
                label={`Pregunta ${index}`}
                disabled
              />
            )
          )}
      </Grid>
    </Grid>
  );
};

export default UserTestResolve;
