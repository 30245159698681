import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
const AdminRoute = ({ children, ...remainingProps }) => {
  const role = useSelector((state) => state.firebase.profile.role);
  return (
    <Route
      {...remainingProps}
      render={({ location }) =>
        role && role === "admin" ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/dashboard",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
export default AdminRoute;
