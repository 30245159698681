import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  List,
  ListItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
  DialogActions,
  Button,
} from "@material-ui/core";
import AddCircleButton from "@material-ui/icons/AddCircleOutline";
const QuestionDialog = ({
  openModal,
  setOpenModal,
  indicators,
  addQuestion,
  questionElement,
  setQuestionElement,
  updateQuestion,
}) => {
  const [question, setQuestion] = useState(questionElement?.question ?? "");
  const [selectedIndicator, setSelectedIndicator] = useState("");
  const [indicatorList, setIndicatorList] = useState(
    questionElement?.indicators ?? []
  );
  const [importance, setImportance] = useState();
  const [affection, setAffection] = useState();
  const [affectionError, setAffectionError] = useState();

  useEffect(() => {
    if (questionElement?.indicators)
      setIndicatorList(questionElement.indicators);
  }, [questionElement]);

  const handleIndicatorAdd = (title, importance, affection) => {
    if (isNaN(affection)) {
      setAffectionError("Debe ingresar un numero");
    } else {
      const indicator = {
        title: title,
        importance: importance,
        affection: affection,
      };
      setIndicatorList([...indicatorList, indicator]);
      setSelectedIndicator("");
    }
  };

  const handleClose = () => {
    setSelectedIndicator("");
    setQuestion("");
    setAffection("");
    setImportance("");
    setIndicatorList([]);
    setQuestionElement(undefined);
    setOpenModal(false);
  };

  const handleSubmit = (question) => {
    if (questionElement) updateQuestion(question);
    else addQuestion(question);
    handleClose();
  };
  return (
    <Dialog
      open={openModal}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Nueva Pregunta</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Cargue la pregunta y sus indicadores, una vez terminado de cargar los
          indicadores, agregue la pregunta.
        </DialogContentText>
        <TextField
          autoFocus
          variant="outlined"
          margin="dense"
          id="name"
          label="Pregunta"
          fullWidth
          onChange={(e) => setQuestion(e.target.value)}
          defaultValue={questionElement?.question ?? question}
        />
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <FormControl style={{ minWidth: "120px" }}>
              <InputLabel id="demo-simple-select-label">Indicador</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedIndicator}
                onChange={(e) => setSelectedIndicator(e.target.value)}
              >
                {indicators &&
                  indicators
                    .filter((e) => {
                      if (indicatorList.some((j) => j.title === e.title))
                        return false;
                      return true;
                    })
                    .map((element) => (
                      <MenuItem key={element.id} value={element}>
                        {element.title}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            {indicatorList &&
              indicatorList.length !== 0 &&
              indicatorList.map((element) => (
                <Chip
                  key={element.title}
                  label={`${element.title} ${
                    element.importance === "minus"
                      ? ` -${element.affection}`
                      : element.importance === "plus"
                      ? ` +${element.affection}`
                      : ``
                  }`}
                  onDelete={() => {
                    setIndicatorList(
                      indicatorList.filter(
                        (indicatorElement) =>
                          indicatorElement.title !== element.title
                      )
                    );
                    setSelectedIndicator("");
                  }}
                />
              ))}
          </Grid>
        </Grid>
        {selectedIndicator && (
          <Grid item>
            <List>
              <ListItem>
                <Grid container spacing={3} alignItems="center">
                  <Grid item>{selectedIndicator.title}</Grid>
                  <Grid item>
                    <RadioGroup
                      aria-label="position"
                      onChange={(e) => {
                        setImportance(e.target.value);
                      }}
                    >
                      <FormControlLabel
                        value="minus"
                        control={<Radio color="primary" />}
                        label="-"
                      />
                      <FormControlLabel
                        value="none"
                        control={<Radio color="primary" />}
                        label="O"
                      />
                      <FormControlLabel
                        value="plus"
                        control={<Radio color="primary" />}
                        label="+"
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid item>
                    <TextField
                      id="intention"
                      label="Afeccion"
                      type="text"
                      onChange={(e) =>
                        setAffection(e.target.value.replace(",", "."))
                      }
                      error={affectionError ? true : false}
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <IconButton
                    disabled={
                      selectedIndicator && importance && affection
                        ? false
                        : true
                    }
                    onClick={() =>
                      handleIndicatorAdd(
                        selectedIndicator.title,
                        importance,
                        affection
                      )
                    }
                  >
                    <AddCircleButton />
                  </IconButton>
                </Grid>
              </ListItem>
            </List>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancelar
        </Button>
        {!questionElement ? (
          <Button
            onClick={() =>
              handleSubmit({
                question: question,
                indicators: indicatorList,
              })
            }
            color="primary"
            disabled={
              indicatorList &&
              indicatorList.length !== 0 &&
              (question || question.question)
                ? false
                : true
            }
          >
            Agregar
          </Button>
        ) : (
          <Button
            onClick={() =>
              handleSubmit({
                id: questionElement.id,
                question: question,
                indicators: indicatorList,
              })
            }
            color="primary"
            disabled={
              indicatorList && indicatorList.length !== 0 ? false : true
            }
          >
            Actualizar
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default QuestionDialog;
