import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { Switch, Route } from "react-router-dom";
import TestList from "./examList";
import TestCreate from "./createExam";
import TestEdit from "./editExam";
const Tests = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item>
            <Typography variant="h5" gutterBottom>
              Tests
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Switch>
        <Route path="/tests/create" component={TestCreate} />
        <Route path="/tests/edit/:id" component={TestEdit} />
        <Route path="/tests" component={TestList} />
      </Switch>
    </Grid>
  );
};

export default Tests;
