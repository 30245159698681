import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import InserEmoticonIcon from "@material-ui/icons/InsertEmoticon";
import HomeIcon from "@material-ui/icons/Home";
import PeopleIcon from "@material-ui/icons/People";
import BarChartIcon from "@material-ui/icons/BarChart";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { List } from "@material-ui/core";
import { Link } from "react-router-dom";

export const mainListItems = (
  <div>
    <ListItem button component={Link} to="/">
      <ListItemIcon>
        <HomeIcon />
      </ListItemIcon>
      <ListItemText primary="Inicio" />
    </ListItem>
    <ListItem button component={Link} to="/tests">
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Tests" />
    </ListItem>
    <ListItem button component={Link} to="/indicators">
      <ListItemIcon>
        <InserEmoticonIcon />
      </ListItemIcon>
      <ListItemText primary="Indicadores" />
    </ListItem>
    <ListItem button component={Link} to="/users">
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Usuarios" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="Resultados" />
    </ListItem>
  </div>
);

export const SecondaryListItems = ({ onClick }) => {
  return (
    <List>
      <ListItem button onClick={onClick}>
        <ListItemIcon>
          <ExitToAppIcon style={{ color: "red" }} />
        </ListItemIcon>
        <ListItemText primary="Salir" />
      </ListItem>
    </List>
  );
};
