import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  CircularProgress,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  CardActions,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import { useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import { Link, useHistory } from "react-router-dom";
const UserList = () => {
  useFirestoreConnect([{ collection: "users" }]);
  const users = useSelector((state) => state.firestore.ordered.users);
  const [filteredUsers, setFilteredUsers] = useState(undefined);
  const [searchParam, setSearchParam] = useState(undefined);
  const history = useHistory();
  const requesting = useSelector(
    (state) => state.firestore.status.requesting.indicators
  );
  useEffect(() => {
    if (searchParam)
      setFilteredUsers(
        users.filter(
          (element) =>
            element.name.includes(searchParam) ||
            element.lastName.includes(searchParam) ||
            element.email.includes(searchParam)
        )
      );
    else setFilteredUsers(undefined);
  }, [searchParam, users]);
  return (
    <Grid item xs={12}>
      <Grid container spacing={3} alignItems="center">
        <Grid item>Listado de usuarios</Grid>
        <Grid item>
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-adornment-amount">Buscar</InputLabel>
            <OutlinedInput
              onChange={(e) => setSearchParam(e.target.value)}
              label="Buscar"
              id="outlined-adornment-amount"
              endAdornment={
                <InputAdornment postition="end">
                  <SearchIcon color="secondary" />
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={3} alignItems="center">
        {requesting ? (
          <CircularProgress />
        ) : filteredUsers ? (
          filteredUsers
            .filter((element) => element.role !== "admin")
            .map((element) => (
              <Grid key={element.id} item xs={12} md={6} lg={4}>
                <Card elevation={7}>
                  <CardActionArea
                    onClick={() =>
                      history.push(`/users/${element.id}`, element)
                    }
                  >
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        {element.name} {element.lastName}
                      </Typography>
                      <Typography gutterBottom variant="subtitle1">
                        {element.email}
                      </Typography>
                      <Grid container spacing={3}>
                        <Grid item>
                          <Typography variant="h6" component="h5">
                            Tests terminados{" "}
                            {element.tests?.filter((test) => test.submitted)
                              .length ?? "0"}
                          </Typography>
                          <Typography variant="h6" component="h5">
                            Tests en proceso{" "}
                            {element.tests?.filter((test) =>
                              test.questions.some(
                                (question) => question.result !== undefined
                              )
                            ).length ?? "0"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button
                      size="small"
                      color="primary"
                      component={Link}
                      to={{
                        pathname: `/users/${element.id}`,
                        state: element,
                      }}
                    >
                      Ver mas
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))
        ) : (
          users &&
          users
            .filter((element) => element.role !== "admin")
            .map((element) => (
              <Grid key={element.id} item xs={12} md={6} lg={4}>
                <Card elevation={7}>
                  <CardActionArea
                    onClick={() =>
                      history.push(`/users/${element.id}`, element)
                    }
                  >
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        {element.name} {element.lastName}
                      </Typography>
                      <Typography gutterBottom variant="subtitle1">
                        {element.email}
                      </Typography>
                      <Grid container spacing={3}>
                        <Grid item>
                          <Typography variant="h6" component="h5">
                            Tests terminados{" "}
                            {element.tests?.filter((test) => test.submitted)
                              .length ?? "0"}
                          </Typography>
                          <Typography variant="h6" component="h5">
                            Tests en proceso{" "}
                            {element.tests?.filter((test) =>
                              test.questions.some(
                                (question) => question.result !== undefined
                              )
                            ).length ?? "0"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button
                      size="small"
                      color="primary"
                      component={Link}
                      to={{
                        pathname: `/users/${element.id}`,
                        state: element,
                      }}
                    >
                      Ver mas
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))
        )}
      </Grid>
    </Grid>
  );
};

export default UserList;
