import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { Switch, Route } from "react-router-dom";
import IndicatorCreate from "./createIndicator";
import IndicatorEdit from "./editIndicator";
import IndicatorList from "./indicatorList";
const Indicators = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item>
            <Typography variant="h5" gutterBottom>
              Indicadores
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Switch>
        <Route path="/indicators/create" component={IndicatorCreate} />
        <Route path="/indicators/edit/:id" component={IndicatorEdit} />
        <Route path="/indicators" component={IndicatorList} />
      </Switch>
    </Grid>
  );
};

export default Indicators;
