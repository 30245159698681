import React from "react";
import {
  Grid,
  Button,
  CircularProgress,
  List,
  ListItem,
  Paper,
  ListItemText,
  ListItemSecondaryAction,
  Switch,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Link } from "react-router-dom";
import { useFirestoreConnect, useFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";
const TestList = () => {
  useFirestoreConnect([{ collection: "tests" }]);
  const tests = useSelector((state) => state.firestore.ordered.tests);
  const requesting = useSelector(
    (state) => state.firestore.status.requesting.tests
  );
  const firestore = useFirestore();
  const handleDisable = (id, status) => {
    firestore.collection("tests").doc(id).update({ active: status });
  };
  return (
    <Grid item xs={12}>
      <Grid container spacing={3} alignItems="center">
        <Grid item>Listado de Tests</Grid>
        <Grid item>
          <Button
            variant="text"
            color="primary"
            component={Link}
            to="/tests/create"
          >
            Nuevo
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs>
          {requesting ? (
            <CircularProgress />
          ) : (
            tests && (
              <List>
                {tests.map((element) => (
                  <ListItem
                    component={Paper}
                    elevation={7}
                    style={{ marginBottom: "15px" }}
                    key={element.id}
                  >
                    <ListItemText primary={element.title} />
                    <ListItemSecondaryAction>
                      <Grid
                        container
                        direction="row"
                        spacing={2}
                        alignItems="center"
                      >
                        {!element.questions && (
                          <Grid item>
                            <Alert severity="error">
                              Debe terminar de cargar las preguntas
                            </Alert>
                          </Grid>
                        )}
                        <Grid item>
                          <Button
                            variant="text"
                            color="primary"
                            component={Link}
                            to={`tests/edit/${element.id}`}
                          >
                            Preguntas
                          </Button>
                        </Grid>
                        <Grid item>
                          <Switch
                            checked={element.active}
                            onChange={() =>
                              handleDisable(element.id, !element.active)
                            }
                          />
                        </Grid>
                      </Grid>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            )
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TestList;
