import React, { createRef, useState } from "react";
import {
  Grid,
  Button,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  Table,
  CircularProgress,
} from "@material-ui/core";
import { useFirestoreConnect, useFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";
import ExamForm from "./examForm";
import { CSVReader } from "react-papaparse";
const TestEdit = (props) => {
  const buttonRef = createRef();
  useFirestoreConnect(() => [
    {
      collection: "tests",
      doc: props.match.params.id,
    },
    { collection: "indicators" },
  ]);
  const test = useSelector(
    ({ firestore: { data } }) => data.tests && data.tests[props.match.params.id]
  );
  const indicators = useSelector((state) => state.firestore.ordered.indicators);
  const requesting = useSelector(
    (state) => state.firestore.status.requesting.indicators
  );

  const [csvData, setCsvData] = useState();
  const [loadingQuestionUpload, setLoadingQuestionUpload] = useState(false);

  const firestore = useFirestore();

  const handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  const handleOnFileLoad = (data) => {
    console.log(data);
    setCsvData(data);
  };

  const handleOnError = (err, file, inputElem, reason) => {
    alert(err);
  };

  const handleOnRemoveFile = (data) => {
    setCsvData(undefined);
  };

  const handleRemoveFile = (e) => {
    setCsvData(undefined);
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.removeFile(e);
    }
  };

  const handleDeleteAllQuestions = () => {
    if (test.questions) {
      firestore
        .collection("tests")
        .doc(props.match.params.id)
        .update({ questions: [] });
    }
  };

  const checkForIndicators = () => {
    setLoadingQuestionUpload(true);
    const fileIndicatorList = [];
    csvData.forEach((row, index) => {
      if (index === 0) {
        row.data.forEach((column, colIndex) => {
          if (colIndex !== 0 && colIndex % 2 === 0) {
            fileIndicatorList.push(row.data[colIndex]);
          }
        });
      }
    });

    const missingIndicators = fileIndicatorList.filter(
      (element) => !indicators.some((indicator) => indicator.title === element)
    );

    if (missingIndicators.length > 0) {
      var batch = firestore.batch();
      missingIndicators.forEach((indicator) => {
        var docRef = firestore.collection("indicators").doc();
        batch.set(docRef, {
          active: true,
          title: indicator,
        });
      });
      batch.commit().then(() => handleUploadQuestions());
    } else {
      handleUploadQuestions();
    }
  };

  console.log(test?.questions);

  const handleUploadQuestions = () => {
    setLoadingQuestionUpload(true);
    const questionList = [];
    csvData.forEach((row, index) => {
      if (index !== 0 && row.data[0]) {
        const indicators = [];
        row.data.forEach((column, index) => {
          if (index !== 0) {
            if (index % 2 !== 0) {
              indicators.push({
                affection: column,
                importance: row.data[index + 1],
                title: csvData[0].data[index + 1],
              });
            }
          }
        });
        questionList.push({
          id: index,
          question: row.data[0],
          indicators: indicators,
        });
      }
    });
    firestore
      .collection("tests")
      .doc(props.match.params.id)
      .update({ questions: questionList })
      .then(() => {
        setLoadingQuestionUpload(false);
        handleRemoveFile();
      });
  };
  if (loadingQuestionUpload)
    return (
      <Grid item xs={12}>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      </Grid>
    );
  return (
    <Grid item xs={12}>
      <Grid container spacing={3}>
        <Grid item>Editar Test {test && test.title}</Grid>
      </Grid>
      {test && !requesting && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ExamForm
              test={test}
              testId={props.match.params.id}
              indicators={indicators}
              testQuestions={test.questions}
            />
          </Grid>
          <Grid item>
            <CSVReader
              ref={buttonRef}
              onFileLoad={handleOnFileLoad}
              onError={handleOnError}
              noClick
              noDrag
              onRemoveFile={handleOnRemoveFile}
            >
              {({ file }) => (
                <>
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    spacing={3}
                  >
                    <Grid item>
                      <Button
                        variant="outlined"
                        onClick={handleOpenDialog}
                        color="inherit"
                      >
                        Buscar archivo
                      </Button>
                    </Grid>
                    <Grid item>{file && file.name}</Grid>
                    {file && (
                      <Grid item>
                        <Button
                          variant="text"
                          color="secondary"
                          onClick={handleRemoveFile}
                        >
                          Remover Archivo
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    spacing={3}
                  >
                    {file && csvData && !test.questions && (
                      <Grid item>
                        <Button
                          variant="text"
                          color="primary"
                          onClick={checkForIndicators}
                        >
                          Cargar preguntas
                        </Button>
                      </Grid>
                    )}
                    {file && csvData && test.questions && (
                      <Grid item>
                        <Grid container spacing={3}>
                          <Grid item>
                            <p>
                              Para cargar preguntas primero debe borrar las
                              anteriores
                            </p>
                          </Grid>
                          <Grid item>
                            <Button
                              onClick={() => handleDeleteAllQuestions()}
                              variant="contained"
                              style={{
                                backgroundColor: "red",
                                color: "white",
                              }}
                            >
                              Borrar
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </>
              )}
            </CSVReader>
          </Grid>
          {csvData && (
            <Grid item xs={12}>
              <TableContainer component={Paper} elevation={7}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Pregunta</TableCell>
                      {csvData[0].data.map((element, index) => {
                        if (index > 1) {
                          if (index % 2 === 0) {
                            return (
                              <TableCell key={`${element}${index}`}>
                                {element}
                              </TableCell>
                            );
                          }
                        }
                        return null;
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {csvData.map((rowElement, index) => {
                      if (index !== 0) {
                        return (
                          <TableRow key={index}>
                            {rowElement.data.map((element, index) => {
                              if (index === 0) {
                                return (
                                  <TableCell key={`${element}${index}`}>
                                    {element}
                                  </TableCell>
                                );
                              } else if (index % 2 !== 0) {
                                return (
                                  <TableCell key={`${element}${index}`}>
                                    <Paper
                                      elevation={7}
                                      style={{
                                        backgroundColor:
                                          rowElement.data[index + 1] === "minus"
                                            ? "red"
                                            : rowElement.data[index + 1] ===
                                              "plus"
                                            ? "lightgreen"
                                            : "inherit",
                                        padding: "16px",
                                      }}
                                    >
                                      {element}
                                    </Paper>
                                  </TableCell>
                                );
                              }
                              return null;
                            })}
                          </TableRow>
                        );
                      }
                      return null;
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default TestEdit;
