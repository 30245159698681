import React, { useEffect, useState } from "react";
import {
  Grid,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TableContainer,
  Table,
  TableHead,
  Typography,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
const ExamResult = (props) => {
  useFirestoreConnect(() => [{ collection: "indicators" }]);
  const indicators = useSelector((state) => state.firestore.ordered.indicators);
  const [test, setTest] = useState();
  useEffect(() => {
    if (props.location.state) setTest(props.location.state);
  }, [props]);
  const getIndicatorResult = (indicator) => {
    var result = 0;
    test.questions.forEach((element) => {
      const questionResult = element.result;
      const currentIndicator = element.indicators.find(
        (element) => element.title === indicator.title
      );
      if (currentIndicator) {
        if (currentIndicator.importance === "plus") {
          result += questionResult * currentIndicator.affection;
        } else if (currentIndicator.importance === "minus") {
          result += questionResult * (currentIndicator.affection * -1);
        }
      }
    });
    return result;
  };

  const getIndicatorMinus = (indicator) => {
    var result = 0;
    test.questions.forEach((element) => {
      const currentIndicator = element.indicators.find(
        (element) => element.title === indicator.title
      );
      if (currentIndicator) {
        if (currentIndicator.importance === "minus") {
          result += 5 * (currentIndicator.affection * -1);
        }
      }
    });
    return result;
  };

  const getIndicatorMax = (indicator) => {
    var result = 0;
    test.questions.forEach((element) => {
      const currentIndicator = element.indicators.find(
        (element) => element.title === indicator.title
      );
      if (currentIndicator) {
        if (currentIndicator.importance === "plus") {
          result += 5 * currentIndicator.affection;
        }
      }
    });
    return result;
  };

  const getIndicatorPercent = (indicator) => {
    var result = 0;
    const maximum = getIndicatorMax(indicator);
    const minimum = getIndicatorMinus(indicator);
    const indicatorResult = getIndicatorResult(indicator);

    const absoluteValue = maximum + minimum * -1;
    var test = 0;
    if (indicatorResult >= 0) {
      test = minimum * -1 + indicatorResult;
    } else {
      test = minimum * -1 + indicatorResult;
    }
    result = (test * 100) / absoluteValue;

    return result.toFixed(2);
  };

  return (
    <Grid item xs={12}>
      <Grid container spacing={3}>
        <Grid item>Editar Test {test && test.title}</Grid>
      </Grid>
      {test && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {test && test.questions && (
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item style={{ alignSelf: "center" }}>
                    <Typography variant="subtitle1">
                      {test.questions.length} preguntas
                    </Typography>
                  </Grid>
                </Grid>
                <br />
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TableContainer component={Paper} elevation={7}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Indicador</TableCell>
                            <TableCell>Resultado</TableCell>
                            <TableCell>Porcentual</TableCell>
                            <TableCell>Minimo</TableCell>
                            <TableCell>Maximo</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {indicators &&
                            indicators.map((element) => (
                              <TableRow key={element.title}>
                                <TableCell>{element.title}</TableCell>
                                <TableCell>
                                  {getIndicatorResult(element)}
                                </TableCell>
                                <TableCell>
                                  {getIndicatorPercent(element)} %
                                </TableCell>
                                <TableCell>
                                  {getIndicatorMinus(element)}
                                </TableCell>
                                <TableCell>
                                  {getIndicatorMax(element)}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                  <Grid item xs={12}>
                    {test.questions && test.questions.length !== 0 && (
                      <TableContainer component={Paper} elevation={7}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Pregunta</TableCell>
                              <TableCell>Respuesta</TableCell>
                              {indicators &&
                                indicators.length > 0 &&
                                indicators.map((element) => (
                                  <TableCell key={`${element.title}`}>
                                    {element.title}
                                  </TableCell>
                                ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {test.questions.map((questionElement) => (
                              <TableRow key={`${questionElement.id}`}>
                                <TableCell>
                                  {questionElement.question}
                                </TableCell>
                                <TableCell>{questionElement.result}</TableCell>
                                {indicators &&
                                  indicators.length > 0 &&
                                  indicators.map((element) => (
                                    <TableCell
                                      key={`${element.title}`}
                                      style={{ padding: "0px" }}
                                    >
                                      {questionElement.indicators
                                        .filter(
                                          (indicatorElement) =>
                                            indicatorElement.title ===
                                            element.title
                                        )
                                        .map((result) => (
                                          <Paper
                                            key={result.title}
                                            elevation={7}
                                            style={{
                                              backgroundColor:
                                                result.importance === "minus"
                                                  ? "red"
                                                  : result.importance === "plus"
                                                  ? "lightgreen"
                                                  : "inherit",
                                              padding: "16px",
                                            }}
                                          >
                                            {result.affection}
                                          </Paper>
                                        ))}
                                    </TableCell>
                                  ))}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </Grid>
                  <Grid item xs={12}></Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default ExamResult;
