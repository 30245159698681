import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useFirebase } from "react-redux-firebase";
import { isLoaded, isEmpty } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { Redirect, Route, Link as RouterLink } from "react-router-dom";
import { CircularProgress, Link } from "@material-ui/core";
import { Switch } from "react-router-dom";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(https://source.unsplash.com/random)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignInSide() {
  const classes = useStyles();
  const firebase = useFirebase();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [busy, setBusy] = useState(false);
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const auth = useSelector((state) => state.firebase.auth);
  const signIn = (e) => {
    e.preventDefault();
    setBusy(true);
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => setBusy(false));
  };

  const register = (e) => {
    e.preventDefault();
    setBusy(true);
    firebase
      .createUser(
        { email, password, signIn: true },
        { email, name, lastName, role: "user" }
      )
      .then(() => setBusy(false));
  };
  if (isLoaded(auth) && !isEmpty(auth)) return <Redirect to="/home" />;
  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid item xs={12}>
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Mejor Test
              </Typography>
              <Switch>
                <Route path="/login">
                  <form className={classes.form} onSubmit={signIn}>
                    <TextField
                      type="email"
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Correo"
                      name="email"
                      autoComplete="email"
                      onChange={(e) => setEmail(e.target.value)}
                      autoFocus
                    />
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Contraseña"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      onChange={(e) => setPassword(e.target.value)}
                    />

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    >
                      {busy ? (
                        <>
                          <CircularProgress
                            size={25}
                            style={{ color: "white" }}
                          />
                        </>
                      ) : (
                        <>Ingresar</>
                      )}
                    </Button>
                    <Grid container>
                      <Grid item xs={12}>
                        <Link
                          variant="body2"
                          component={RouterLink}
                          to="/register"
                        >
                          Registrarme
                        </Link>
                        <br />
                      </Grid>
                      <Grid item xs>
                        <Link href="#" variant="body2">
                          Olvide mi contraseña
                        </Link>
                      </Grid>
                    </Grid>
                    <Box mt={5}>
                      <Copyright />
                    </Box>
                  </form>
                </Route>
                <Route path="/register">
                  <form className={classes.form} onSubmit={register}>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="name"
                          label="Nombre"
                          name="name"
                          onChange={(e) => setName(e.target.value)}
                          autoFocus
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="lastName"
                          label="Apellido"
                          name="lastName"
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </Grid>
                    </Grid>
                    <TextField
                      type="email"
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Correo"
                      name="email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Contraseña"
                      type="password"
                      id="password"
                      onChange={(e) => setPassword(e.target.value)}
                    />

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    >
                      {busy ? (
                        <>
                          <CircularProgress
                            size={25}
                            style={{ color: "white" }}
                          />
                        </>
                      ) : (
                        <>Ingresar</>
                      )}
                    </Button>
                    <Grid container>
                      <Grid item xs>
                        <Link
                          href="#"
                          variant="body2"
                          component={RouterLink}
                          to="/login"
                        >
                          Ya tengo cuenta
                        </Link>
                      </Grid>
                    </Grid>
                    <Box mt={5}>
                      <Copyright />
                    </Box>
                  </form>
                </Route>
              </Switch>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
